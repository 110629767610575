<template>
  <q-layout view="lHr Lpr lFr">
    <q-header reveal bordered height-hint="80" class="bg-dark txt-white">
      <div class="row">
        <div class="col-0 col-xl-2 q-px-xl"></div>
        <div class="col-12 col-xl-8 q-px-xl">
          <div class="row ">
            <div class="col-4 q-px-lg q-py-sm">
              <div class="nav navbar" v-if="!inChinese">{{info.name}}'s Homepage</div>
              <div class="nav navbar" v-if="inChinese">{{info.name}}的主页</div>
            </div>
            <div class="col-4 col-sm-2 col-md-1 offset-0 offset-sm-4 offset-md-6 q-px-md q-py-sm">
              <q-toggle v-model="inChinese" size="sm"/>
            </div>
            <div class="col-4 col-sm-2 col-md-1 q-px-md q-py-sm">
              <q-toggle v-model="isDark" size="sm"/>
            </div>
          </div>
        </div>
        <div class="col-0 col-xl-2 q-px-xl"></div>
      </div>
    </q-header>
    <q-page-container>
      <div class="row">
        <div class="col-0 col-xl-2 q-px-xl"></div>
        <div class="col-12 col-xl-8 q-px-xl">
          <div class="row">
            <div class="col-12 col-md-4 q-px-sm q-py-lg">
              <div class="name-title">{{info.name}}</div><br/>
              <p><img class="img-responsive" src="./assets/photo.jpg" alt="" style="max-height:80%; max-width:80%;"></p>
              <br/>
              <div class="row text-md-start q-mr-xl">
                <p>
                  {{ info.position }}<br/> {{ info.institute }}
                </p>
                <p>
                  <span class="text-bold">Email</span>: <a :href="'mailto:' + info.email ">{{ info.email }}</a> <br/>
                  <span class="text-bold">Office</span>: <span style="white-space: pre-wrap; word-wrap: break-word;">{{ info.office }}</span> <br/>
                  <a :href="'https://github.com/' + info.GitHub ">GitHub</a><br/>
                  <a :href="'https://scholar.google.com/citations?user=' + info.GScholar ">Google Scholar</a><br/>
                  <a :href="'https://orcid.org/' + info.ORCID ">ORCID</a><br/>
                </p>
              </div>
            </div>
            <div class="col-12 col-md-8 q-px-sm q-py-lg">
              <div class="row q-py-md">
                <div class="col-12">
                  <div v-if="!inChinese">
                    <div class="subsection-name">Education</div>
                    <ul>
                      <li v-for="item in info.education" :key="item.year">
                        {{item.degree}} {{item.major}} ({{item.year}}), <span class="text-bold">{{item.university}}</span>
                      </li>
                    </ul>
                  </div>
                  <div v-if="inChinese">
                    <div class="subsection-name">教育经历</div>
                    <ul>
                      <li v-for="item in info.education" :key="item.year" class="h-margin-between-items">
                        {{item.year}}年毕业于<span class="text-bold">{{item.university}}</span>，获{{item.major}}{{item.degree}}学位。</li>
                    </ul>
                  </div>
                  <hr>
                </div>
              </div>
              <div class="row q-py-md">
                <div class="col-12">
                  <div class="subsection-name" v-if="!inChinese">Research</div>
                  <div class="subsection-name" v-if="inChinese">研究经历</div>
                  <ul>
                    <li v-for="item in info.research" :key="item.year" class="h-margin-between-items">
                      <span class="text-bold">{{item.direction}}</span> {{(inChinese)? '（'+item.time+'）：' :'('+item.time+'): '}}{{item.desc}}
                    </li>
                  </ul>
                  <hr>
                </div>
              </div>
              <div class="row q-py-md">
                <div class="col-12">
                  <div class="subsection-name" v-if="!inChinese">Publications</div>
                  <div class="subsection-name" v-if="inChinese">文章发表</div>
                    <ul id="publication-list">
                      <li v-for="article in publications" :key="article.Title" class="h-margin-between-items">
                        {{article.Authors}} <b>{{article.Title}}</b>. In: <i>{{article.Publication}}</i> {{article.Year}}<br>
                      </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-0 col-xl-2 q-px-xl"></div>
      </div>
    </q-page-container>
  </q-layout>
</template>

<style>
html {
  overflow-y: scroll;
}

.body--light {
  --q-primary: #1B6FC2;
  --q-secondary: #536390;
  --q-font: #23476A;
  --q-heading: #292922;
}

.body--dark {
  --q-primary: #66CCCC;
  --q-secondary: #818cab;
  --q-font: #CCCCCC;
  --q-heading: #818cab;
}

body.body--light {
  background: #FEFDF9;
}

body.body--dark {
  background: #2C2C2C;
}

.h-margin-between-items {
  margin: 10px 0;
}
</style>


<script>
// import { ref } from 'vue'
import { useQuasar } from 'quasar'


export default {
  name: "Hui Chong's Homepage",

  components: {},

  data (){
    const staticInfo = {
      EN: require('./static/info-en.json'),
      CN: require('./static/info-cn.json')
    }
    let url = new URL(location)
    let params = url.searchParams
    let inChinese = params.get('lang') === "cn"
    let isDark = params.get('theme') === 'dark'
    console.log(inChinese)

    let info
    if (inChinese) {
      info = staticInfo.CN
    } else {
      info = staticInfo.EN
    }
    console.log(require('./static/works.json'))
    return {
      staticInfo: staticInfo,
      inChinese: inChinese,
      isDark: isDark,
      info: info,
      publications: require('./static/works.json'),
      // words_no_capital: ['of', 'in', 'and', 'for', 'or', 'at', 'on', 'from', 'to']
    }
  },

  // computed: {
  //   inChinese() {return this.$route.query.lang === "cn"},
  //   isDark() {return this.$route.query.theme === 'dark'}
  // },

  setup (){
    const $q = useQuasar()
    return {
      setTheme(){
        // console.log('Change theme triggerred')
        $q.dark.toggle()
        if ($q.dark.isActive){
          this.$router.push({query: {...this.$route.query, theme: 'dark'}})
          console.log(this.$route.query)
        } else {
          this.$router.push({query: {...this.$route.query, theme: 'light'}})
          console.log(this.$route.query)
        }
      }
    }
  },

  watch :{
    inChinese: function(){
      this.setLang()
    },
    isDark: function(){
      this.setTheme()
    },
  },

  created(){
    if (this.isDark){
      this.setTheme()
    }
  },

  mounted (){
    this.$nextTick(this.authorTextBold)
  },

  methods: {
    setLang(){
      if (this.inChinese){
        console.log('Change language to CN')
        this.info = this.staticInfo.CN
        this.$router.push({query: {...this.$route.query, lang: 'cn'}})
      } else {
        console.log('Change language to EN')
        this.info = this.staticInfo.EN
        this.$router.push({query: {...this.$route.query, lang: 'en'}})
      }
    },
    authorTextBold(){
      console.log('authorTextBold')
      let publications = document.getElementById('publication-list').innerHTML
      document.getElementById("publication-list").innerHTML = publications.replaceAll('Chong, Hui;', '<b>Chong, Hui</b>;');
    }
  }
}
</script>
