import { createApp } from 'vue'
import App from './App.vue'
import './style/main.css'
import './style/font.css'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createRouter, createWebHistory } from 'vue-router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import VueGtag from "vue-gtag-next"
import { trackRouter } from "vue-gtag-next";


const router = createRouter({
    mode: 'history',
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: "/home",
            name: "Home",
            component: App,
            // props: route => ({ lang: route.query.lang, theme: route.query.theme }),
            meta:{
                title: "Hui Chong's Homepage"
            }
        },
    ],
})

let app = createApp(App)
app.use(Quasar, quasarUserOptions)
app.use(VueAxios, axios)
app.use(VueGtag, {
    property: {
        id: "G-M65FYZSLL9",
    },
})
trackRouter(router)
app.use(router)
app.mount('#app')
